<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0 showFlexListDiv">
          <div class="iq-edit-list showFlexList">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link active" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" data-toggle="pill" href="#contactsocial">{{cvContact}}</a>
              </li>
              <li  class="flex-fill p-0">
                <a class="nav-link" data-toggle="pill" href="#changePassword">
                  Change Password
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade" id="profile" role="tabpanel">
            <iq-card class="mb-4 p-3">
              <div>
                <h4>
                  Profile Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%; ">
                        <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; " />
                        <i v-else class="fas fa-user d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer !important"></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;  pointer-events: none;" @change="openImageDialog($event, 'Profile Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="proPicError"></span>
                  </div>
                  <hr class="m-0" />
                  <button type="button" class="p-2 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading" @click="imageEdit('Profile Pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Save Profile Pic</span>
                  </button>
                  <button v-if="vmProfilePic" type="button" class="p-2 ml-3 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('profile_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelProfilePicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Profile Pic</span>
                  </button>
                </b-form>
              </div>
            </iq-card>
            <iq-card class="mb-4 p-3">
              <div>
                <h4>
                  Cover Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden;" />
                        <i v-else class="fas fa-user d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor:pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png image/jpeg" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                  <button type="button" class="p-2 rounded btn btn-primary border-0 " :disaled="cvCoverPicError || cvCoverPicLoading" @click="imageEdit('Cover Pic')" >
                    <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else> Save Cover Pic</span>
                  </button>
                  <button v-if="vmCoverPic" type="button" class="p-2 ml-3 rounded btn btn-primary border-0 " :disabled="cvProfilePicError || cvProfilePicLoading"  @click="imageRemove('cover_pic')">
                    <b-spinner small class="ml-4 mr-4" v-if="cvDelCoverPicLoading" label="Spinning">
                    </b-spinner>
                    <span v-else>Remove Cover Pic</span>
                  </button>
                </b-form>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade active show" id="about" role="tabpanel">
            <iq-card class="mb-2 p-3">
              <div>
                <h4 class>
                  About
                </h4>
                <hr class="mb-0"/>
                <div class="mt-3">
                  <AboutProfile :propUserObj="userData"/>
                </div>
              </div>
            </iq-card>
            <div class="mt-3">
              <PersonalInfo v-if="userData.user_id" :propUserId="userData.user_id" propDisplayHeader="1"/>
            </div>
            <iq-card class="mb-2 p-3">
              <div class="">
               <template>
                  <h4>Education
                    <span class="educationAddIcon" @click="showAddEducation()"><i class="fa-solid fa-circle-plus"></i></span>
                  </h4>
                  <hr class="mb-0"/>
                  <div v-if="user_profile.education && user_profile.user_id && user_profile.education.length" class="mt-3">
                    <Education :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" @emitUserProfileEducation="addEducation">
                    </Education>
                  </div>
                </template>
              </div>
            </iq-card>
            <iq-card class="mb-2 p-3">
              <div class="">
                <template>
                  <h4>Program and test
                    <span class="educationAddIcon" @click="showProgramModal()"><i class="fa-solid fa-circle-plus"></i></span>
                  </h4>
                  <hr class="mb-0"/>
                  <div v-if="userProgramTest && Object.keys(userProgramTest).length" class="mt-3">
                    <div class="educationCard" v-for="(item, index) in userProgramTest" :key="index+'program'" >
                      <ProgramTest :propProgramDetail="item" :propOpenedInModal="false">
                      </ProgramTest>
                    </div>
                  </div>
                </template>
              </div>
            </iq-card>
          </div>
          <div class="tab-pane fade" id="contactsocial" role="tabpanel">
            <iq-card class="mb-2">
              <b-form class="p-3">
                <template>
                  <h4>Website and Social Links</h4>
                  <hr/>
                </template>
                <div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Website</label>
                    <input v-model="vmSocialLinkFormData.social_links.website" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Facebook</label>
                    <input v-model="vmSocialLinkFormData.social_links.facebook" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Twitter</label>
                    <input v-model="vmSocialLinkFormData.social_links.twitter" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Instagram</label>
                    <input v-model="vmSocialLinkFormData.social_links.instagram" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Youtube</label>
                    <input v-model="vmSocialLinkFormData.social_links.youtube" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Linkedin</label>
                    <input v-model="vmSocialLinkFormData.social_links.linkedin" type="text" class="form-control" required />
                  </div>
                </div>
                <button type="button" class="btn-primary border-0 px-3 py-2 rounded" :disabled="cvSocialLinkLoading" @click="socialLinksEdit()" >
                  <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus" label="Spinning">
                  </b-spinner>
                  <span v-else>Save</span>
                </button>
              </b-form>
            </iq-card>
          </div>

          <div class="tab-pane fade" id="changePassword" role="tabpanel">
            <iq-card class="mb-2">
                <b-form class="p-3">
                  <div>
                    <h4>
                      Change Password
                    </h4>
                    <hr />
                    <div class="form-group">
                      <label for="cpass">{{ cvCurrPassLabel }}:</label>
                      <input type="Password"  class="form-control" id="cpass" autocomplete="off" v-model="vmCurrentPassword" v-on:change="vmCurrentPassword = vmCurrentPassword.trim()"/>
                      <span class="text-danger" v-if="errorFlag && !vmCurrentPassword" >{{ cvCurrPassMsg }}</span>
                    </div>
                    <div class="form-group">
                      <label for="npass">{{ cvNewPassLabel }}:</label>
                      <input  type="Password" class="form-control"  id="npass" v-model="vmNewPassword" autocomplete="off"/>
                      <span class="text-danger" v-if="errorFlag && !vmNewPassword" >{{ cvNewPassMsg }}</span>
                      <span class="text-danger" v-if="errorFlag && vmNewPassword.length < 6" >{{ cvLengthPassMsg }}</span>
                    </div>
                    <div class="form-group">
                      <label for="vpass">{{ cvConfirmPassLabel }}:</label>
                      <input type="Password" class="form-control" id="vpass" v-model="vmConfirmPassword"  autocomplete="off" />
                      <span class="text-danger"  v-if="errorFlag && !vmConfirmPassword">{{ cvConfirmPassMsg }}</span>
                      <span class="text-danger" v-if="errorFlag && vmConfirmPassword.length < 6" >{{ cvLengthPassMsg }}</span>
                      <span class="text-danger" v-if=" errorFlag && vmConfirmPassword && vmNewPassword &&vmConfirmPassword !== vmNewPassword ">{{ cvExactPassMsg }}</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="changePassword"
                    class="btn btn-primary mr-2">
                    {{ cvSubmitBtn }}
                  </button>
                </b-form>
            </iq-card>
          </div>
        </div>
      </div>
    </div>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <template v-if="addEducationShow">
      <Education :propEducationAddShow='addEducationShow'
                 @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
    </template>
    <ProgramTest v-if="showProgramTestModal" :propProgramDetail="programEditObj" :propOpenedInModal="showProgramTestModal" @emitCloseProgramModal="closeProgramModal">
    </ProgramTest>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate"
import { socialvue } from "../../../../config/pluginInit"
import { User } from "../../../../FackApi/api/user"
import { Images } from "../../../../FackApi/api/image"
import { SocialLinks } from "../../../../FackApi/api/socialLink"
import ApiResponse from "../../../../Utils/apiResponse.js"
import CropImage from "../../../../components/cropImage.vue"
import ProgramTest from "../../../../components/UserProfile/ProgramTest.vue"
import Education from "../../../../components/UserProfile/Education.vue"
import PersonalInfo from "../../../../components/UserProfile/PersonalInfo.vue"
import AboutProfile from "../../../../components/UserProfile/AboutProfile.vue"

export default {
  name: "ProfileEdit",
  component: {
    ValidationProvider
  },
  components: {
    CropImage,
    ProgramTest,
    Education,
    PersonalInfo,
    AboutProfile
  },
  data () {
    return {
      cvLoadingStatus: false,
      cvSelectLabel: "Select ",
      cvProfileInfo: "Image",
      cvAbout: "Profile",
      cvContact: "Website and Social Links",
      cvSubmitBtn: "Edit",
      cvCurrPassLabel: "Current Password",
      cvNewPassLabel: "New Password",
      cvConfirmPassLabel: "Confirm Password",
      cvCurrPassMsg: "Current Password is requirerd. ",
      cvNewPassMsg: "New Password is required. ",
      cvConfirmPassMsg: "Confirm Password is required. ",
      cvExactPassMsg: "New password and confirm password must be same. ",
      cvLengthPassMsg: "Password length must equal to 6 or above. ",
      cvSocialLinkLoading: false,
      cvProfilePicError: false,
      cvCoverPicError: false,
      cvProfilePicLoading: false,
      cvCoverPicLoading: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      cvDelCoverPicLoading: false,
      cvDelProfilePicLoading: false,
      errorFlag: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      vmCurrentPassword: "",
      vmNewPassword: "",
      vmConfirmPassword: "",
      vmUserProPicImage: Object.assign({}, this.initImageData()),
      vmUserCoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      vmSocialLinkFormData: Object.assign({}, this.initSocialLinks()),
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      addEducationShow: false,
      educationSubmited: false,
      user_profile: {
        education: [],
        extraCuricular: {}
      },
      showProgramTestModal: false,
      userProgramTest: {},
      programEditObj: {},
      extraCurricularListObj: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
      this.educationSubmited = false
    },
    /**
     * showProgramModal
     */
    showProgramModal () {
      this.showProgramTestModal = true
      this.programEditObj = {}
    },
    /**
     * closeProgramModal
     */
    closeProgramModal (event) {
      if (event) {
        this.userProgramTest = JSON.parse(event.resp_data.user_program_test)
      }
      this.showProgramTestModal = false
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.addEducationShow = false
    },
    /*
     * addEducation
     */
    async addEducation (payload) {
      if (!this.user_profile) {
        this.user_profile = {}
      }

      if (!this.user_profile.education) {
        this.user_profile.education = []
      }

      const educationIndex = this.user_profile.education.findIndex(edu => edu.id === payload.newEducation.id)
      if (educationIndex >= 0) {
        this.user_profile.education[educationIndex] = payload.newEducation
      }
      else {
        this.user_profile.education.unshift(payload.newEducation)
      }

      this.user_profile.user_id = this.userData.user_id

      if (payload && payload.extraCurricularList && this.extraCurricularListObj) {
        this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      }

      this.userProfileView()
      this.addEducationShow = false
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.$route.params.user_id,
        module_name: "USER",
        image: [],
        image_type: {}
      }
    },
    /** */
    initSocialLinks () {
      return {
        module_id: this.$route.params.user_id,
        module_name: "USER",
        social_links: {
          website: "",
          facebook: "",
          twitter: "",
          instagram: "",
          youtube: "",
          linkedin: ""
        }
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
      if (this.userData.user_id !== this.$route.params.user_id) {
        this.$router.back()
      }
      this.userProfileView()
      this.imageGetProfileImages()
      this.socialLinksView()
    },
    /**
     * userProfileView
     */
    async userProfileView () {
      let userProfileViewResp = await User.userProfileView(this, this.userData.user_id)
      if (userProfileViewResp.resp_status) {
        if (typeof userProfileViewResp.resp_data.data.user_profile === "string") {
          userProfileViewResp.resp_data.data.user_profile = JSON.parse(userProfileViewResp.resp_data.data.user_profile)
        }

        this.extraCurricularListObj = userProfileViewResp.resp_data.data.extraCurricularListObj
        this.user_profile = userProfileViewResp.resp_data.data.user_profile ? userProfileViewResp.resp_data.data.user_profile : this.user_profile

        this.userProgramTest = JSON.parse(userProfileViewResp.resp_data.data.user_program_test)
        this.user_profile.user_id = userProfileViewResp.resp_data.data.user_id
      }
    },
    /**
     * Change password
     * use to change password
     */
    async changePassword () {
      try {
        if (
          this.vmNewPassword &&
          this.vmCurrentPassword &&
          this.vmConfirmPassword
        ) {
          if (this.vmNewPassword !== this.vmConfirmPassword) {
            this.errorFlag = true
            return null
          }
          // length of password must equal to 6 or above
          if (
            this.vmNewPassword.length < 6 ||
            this.vmConfirmPassword.length < 6
          ) {
            this.errorFlag = true
            return null
          }
          if (!this.errorFlag && this.vmNewPassword && this.vmCurrentPassword) {
            let data = {
              user_id: this.userData.user_id,
              current_password: this.vmCurrentPassword,
              new_password: this.vmNewPassword
            }
            let profileEditPassResp = await User.userPasswordChange(this, data)
            await ApiResponse.responseMessageDisplay(this, profileEditPassResp)
            if (profileEditPassResp && !profileEditPassResp.resp_status) {
              return false
            }
          }
          else {
            this.errorFlag = true
          }
        }
        else {
          this.errorFlag = true
        }
      }
      catch (err) {
        console.error("Exception occurred at changePassword() and Exception:", err.message)
      }
      finally {
        this.vmCurrentPassword = ""
        this.vmNewPassword = ""
        this.vmConfirmPassword = ""
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let userId = this.$route.params.user_id
        let imageProfileResp = await Images.imageListProfileImages(this, userId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "Profile Pic" && this.vmUserProPicImage.image.length === 0) {
        return false
      }
      if (imageType === "Cover Pic" && this.vmUserCoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = true
          image = this.vmUserProPicImage
        }
        else {
          this.cvCoverPicLoading = true
          image = this.vmUserCoverPicImage
        }
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        await this.setImageToUserData(imageType, imageUpdateResp.resp_data)
        this.vmUserProPicImage = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        if (imageType === "Profile Pic") {
          this.cvProfilePicLoading = false
        }
        else {
          this.cvCoverPicLoading = false
        }
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.$route.params.user_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = ""
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = ""
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * setImageToUserData
     * @param imageType
     * @param images
     * @returns {Promise<void>}
     */
    async setImageToUserData (imageType, images) {
      if (images && images.length > 0) {
        let userProfileData = JSON.parse(JSON.stringify(this.userData))
        if (!userProfileData.user_image) {
          userProfileData.user_image = {}
        }
        let image = {
          image: images[0].image,
          image_id: images[0].image_id,
          image_medium: images[0].image_medium,
          image_thumbnail: images[0].image_thumbnail
        }
        if (imageType === "Profile Pic") {
          userProfileData.user_image["profile_pic"] = image
        }
        else {
          userProfileData.user_image["cover_pic"] = image
        }
        await this.$store.dispatch("User/setUserProfileAction", { ...userProfileData })
      }
    },
    /**
     * socialLinksView
     */
    async socialLinksView () {
      try {
        let userId = this.$route.params.user_id
        let socialLinkViewResp = await SocialLinks.socialLinkView(this, userId)
        if (socialLinkViewResp && !socialLinkViewResp.resp_status) {
          return false
        }
        else {
          this.vmSocialLinkFormData.social_links = JSON.parse(socialLinkViewResp.resp_data.data.social_links)
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksView() and exception", err.message)
      }
    },
    /**
     * socialLinksEdit
     */
    async socialLinksEdit () {
      if (Object.values(this.vmSocialLinkFormData.social_links).filter(Boolean).length < 1) {
        return
      }
      try {
        this.cvSocialLinkLoading = true
        this.vmSocialLinkFormData.module_id = this.$route.params.user_id
        const socialLinksEditResp = await SocialLinks.socialLinkProfileUpdate(this, this.vmSocialLinkFormData)
        await ApiResponse.responseMessageDisplay(this, socialLinksEditResp)
        if (socialLinksEditResp && !socialLinksEditResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksEdit() and exception", err.message)
      }
      finally {
        this.cvSocialLinkLoading = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        this.toastVariant = "danger"
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.toastVariant = ""
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
    * onChangeProfileImage
    */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmUserProPicImage = Object.assign({}, this.initImageData())
        this.vmUserProPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmUserProPicImage.image_type[orgImg.name] = 2
      }
      else {
        this.vmUserCoverPicImage = Object.assign({}, this.initImageData())
        this.vmUserCoverPicImage.image.push(
          {
            "img": img,
            "name": orgImg.name
          }
        )
        this.vmUserCoverPicImage.image_type[orgImg.name] = 3
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.EducationForm {
  .addIcon {
    position: absolute;
    right: 10px;
  }
}
.markRow {
  .addIcon {
    float: right;
    margin-top: -42px;
    font-size: 20px;
    position: relative;
    left: 26px;
    cursor: pointer;
  }
}
.subjectList {
  .closeIcon {
    position: absolute;
    right: 0;
    font-size: 17px;
  }
}
.imageCol {
  height: 110px;
  margin-top: 13px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border: 2px solid aliceblue;
  }
  .closeIcon {
    position: absolute;
    right: 0;
    top: -13px;
    font-size: 20px;
    z-index: 99;
    color: var(--iq-primary);
    fill: var(--iq-primary);
    background: white;
    border-radius: 50%;
    height: 29px;
    cursor: pointer;
  }
}
.ResendSpan {
  color: var(--iq-primary);
  cursor: pointer;
  float: right;
}
.metaPreviewCol {
  // height: 64px;
  // width: 64px;
  height: 108px;
  width: 153px;
  border: 2px solid aliceblue;
  margin-right: 11px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.p-18 {
  padding: 16px !important;
  height: 124px;
}
.fa-solid {
  color: var(--iq-primary);
}
.deleteIconSpan {
  margin-left: 15px;
}
.educationAddIcon {
  float: right;
}
</style>
